const body = document.getElementsByTagName('body')[0];
const header = document.getElementsByClassName('header')[0];

document.addEventListener("DOMContentLoaded", function() {
    burger()
    menu()

    function closeModal() {
        document.getElementById("Modal").remove()
    }
    document.getElementsByClassName("modal__close")[0].addEventListener("click", closeModal)
    document.getElementsByClassName("modal__close-background")[0].addEventListener("click", closeModal)
})

const burger = () => {
    const burger = document.querySelector('.burger__button');
    let oldScroll = 0;
    burger.addEventListener('click', (e)=>{
        header.classList.toggle('header--responsive');
        if(!body.classList.contains('no-scroll')) {
            oldScroll = window.scrollY;
        }
        body.classList.toggle('no-scroll');
        if(!body.classList.contains('no_scroll')) {
            setTimeout(()=>{
                window.scrollTo({
                    left: 0,
                    top: oldScroll
                });
            }, 750);
        }
        e.preventDefault();
    })
};

const menu = () => {
    const presentation = document.getElementById('hero') !== null ? document.getElementById('hero').clientHeight : window.innerHeight / 2;
    let oldScroll = window.scrollY;

    window.addEventListener('scroll', (e) => {
        const transitionPhase = presentation - header.clientHeight;

        if(oldScroll > window.scrollY) {
            if(window.scrollY > header.clientHeight && window.scrollY < transitionPhase) {
                header.classList.add('header--transition');
                header.classList.remove('header--initial');
            }else if(window.scrollY > transitionPhase) {
                header.classList.add('header--sticky');
                header.classList.remove('header--transition');
                header.classList.remove('header--transition-down');
            }else if(window.scrollY < header.clientHeight) {
                header.classList.remove('header--transition');
                header.classList.remove('header--transition-down');
                header.classList.remove('header--sticky');
                header.classList.add('header--initial');
            }
        }else {
            if(window.scrollY > transitionPhase) {
                header.classList.remove('header--sticky');
                header.classList.add('header--transition');
            }else if(window.scrollY > header.clientHeight && window.scrollY < transitionPhase) {
                header.classList.add('header--transition-down');
                header.classList.remove('header--sticky');
                header.classList.remove('header--initial');
            }else if(window.scrollY < header.clientHeight) {
                header.classList.remove('header--transition-down');
                header.classList.add('header--initial');
            }
        }
        oldScroll = window.scrollY;
    });
};